/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import Vue from "vue";
import Vuex from "vuex";
import userApi from "~/api/user";
import ROUTE from "~/constants/routes";
import router from "~/router";
import { setAuthData, logOut, setUserData } from "../helpers/userAuth";
Vue.use(Vuex);

const state = {
  userInfo: null,
  users: {},
  usersList: [],
  user: null,

  contacts: {},
  contactsList: [],

  developers: {},
  developersList: [],

  error: {},
  validationErrors: {},
  loadingProcess: false,
  successRegistration: false,
};

const mutations = {
  setError: (state, payload) => {
    state.error = payload;
  },
  setLoading: (state, payload) => {
    state.loadingProcess = payload;
  },
  setValidationErrors: (state, payload) => {
    state.validationErrors = payload;
  },

  setUsersList: (state, payload) => {
    state.usersList = payload;
  },
  setUsers: (state, payload) => {
    state.users = payload;
  },

  setSuccessRegistration: (state, payload) => {
    state.successRegistration = payload;
  },

  setContactsList: (state, payload) => {
    state.contactsList = payload;
  },
  setContacts: (state, payload) => {
    state.contacts = payload;
  },

  setDevelopersList: (state, payload) => {
    state.developersList = payload;
  },
  setDevelopers: (state, payload) => {
    state.developers = payload;
  },
};

const actions = {
  login({ commit, dispatch }, payload) {
    commit("setLoading", true);
    commit("setError", {});
    commit("setValidationErrors", {});

    userApi
      .login(payload)
      .then((res) => {
        setAuthData(res.data.data.accessToken, res.data.data.expiresIn, {});
        dispatch("getUser");
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  logout({ commit }) {
    commit("setLoading", true);
    userApi
      .logout()
      .then((res) => {
        logOut();
        router.push("/login");
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  getUser({ commit, dispatch }) {
    userApi
      .getProfile()
      .then((res) => {
        setUserData(res.data.data);
        if (res.data.data.groups[0].id == 1) {
          router.push("/");
        } else {
          dispatch("logout");
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      });
  },

  getUsersList({ commit }, payload) {
    commit("setLoading", true);
    userApi
      .getUsersList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        const users = res.data.data;
        for (let i = 0; users.length > i; i++) {
          users[i].userStatus = users[i]?.isActive ? "Активный" : "Не активный";
        }
        commit("setUsersList", users);
        commit("setUsers", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createUser({ commit }, payload) {
    commit("setLoading", true);
    userApi
      .createUser(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.USERS_LIST.replace(":page", 1).replace(":developer", res.data.developerId),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editUser({ commit }, { url, editData }) {
    commit("setLoading", true);
    userApi
      .editUser(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.USERS_LIST.replace(":page", 1).replace(":developer", res.data.developerId),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  //contacts

  getContactsList({ commit }, payload) {
    commit("setLoading", true);
    userApi
      .getContactList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        const contacts = res.data.data;
        commit("setContactsList", contacts);
        commit("setContacts", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createContact({ commit }, payload) {
    commit("setLoading", true);
    userApi
      .createContact(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.CONTACT_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editContact({ commit }, { url, editData }) {
    commit("setLoading", true);
    userApi
      .editContact(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.CONTACT_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  //developers

  getDeveloperList({ commit }, payload) {
    commit("setLoading", true);
    userApi
      .getDeveloperList(payload)
      .then((res) => {
        commit("setError", {});
        commit("setValidationErrors", {});
        const developers = res.data.data;
        for (let i = 0; developers.length > i; i++) {
          developers[i].creditLimit = developers[i].credits.limit;
          developers[i].creditRemaining = developers[i].credits.remaining;
        }
        commit("setDevelopersList", developers);
        commit("setDevelopers", res.data);
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  createDeveloper({ commit }, payload) {
    commit("setLoading", true);
    userApi
      .createDeveloper(payload)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.DEVELOPER_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editDeveloper({ commit }, { url, editData }) {
    commit("setLoading", true);
    userApi
      .editDeveloper(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.DEVELOPER_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  editCredit({ commit }, { url, editData }) {
    commit("setLoading", true);
    userApi
      .editDeveloper(url, editData)
      .then((res) => {
        if (res.success) {
          commit("setError", {});
          commit("setValidationErrors", {});
          router.push({
            path: ROUTE.DEVELOPER_LIST.replace(":page", 1),
          });
        }
      })
      .catch((error) => {
        const { data } = error.response;
        commit("setError", data);
        if (data.errors) {
          commit("setValidationErrors", data.errors);
        }
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

const getters = {};

const users = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
export default users;
