import Vue from "vue";
import "./plugins/axios";
import vSelect from "vue-select";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMask from "v-mask";
import VueMeta from "vue-meta";

import "./styles/app.scss";

Vue.use(VueMask);
Vue.use(VueMeta);

Vue.component("v-select", vSelect);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
