/* eslint-disable */
// eslint conflicts in auto vue create config
import Vue from "vue";
import axios from "axios";
import keysToCamelCase from "camelcase-keys";
import { getAccessToken, logOut, setTokenData } from "~/helpers/userAuth";

const config = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
  baseURL: "https://devapi.intercom-plus.ru",
  withCredentials: false,
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  (config) => {
    //Authorization

    if (getAccessToken()) {
      config.headers.Authorization = `Bearer ${getAccessToken()}`;
    }

    // if (getSiteKey()) {
    //   config.headers.apikey = `${getSiteKey()}`;
    // } else {
    config.headers.apikey = "HJsNhXYNswEzfVv";
    // }

    return config;
  },
  // Do something before request is sent f
  (error) => {
    // if (error.response.status === 401) {
    //   logOut();
    // }
    Promise.reject(error);
  },
);

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    if (response.data) {
      response.data = keysToCamelCase(response.data, { deep: true });
    }
    return response;
  },
  async (error) => {
    const originalConfig = error.config;
    if (
      originalConfig.url !== "/user/login" &&
      originalConfig.url !== "/user/refresh" &&
      error.response.status === 401 &&
      getAccessToken()
    ) {
      // Access Token was expired
      if (error.response.status === 401 && !originalConfig._retry && getAccessToken()) {
        originalConfig._retry = true;

        try {
          const rs = await _axios.post("/user/login");

          setTokenData(rs.data.data.accessToken, rs.data.data.expiresIn);

          return _axios(originalConfig);
        } catch (_error) {
          logOut();
          // return Promise.reject(_error);
        }
      }
    }
    // if (error.response.status === 401) {
    //   document.querySelector(".modal-accesses").classList.add("active");
    // }
    if (error.response && error.response.data) {
      error.response.data = keysToCamelCase(error.response.data, { deep: true });
    }

    return Promise.reject(error);
  },
);

Plugin.install = function (Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
