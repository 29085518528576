export default {
  LOGIN_PAGE: "/login",
  HOME_PAGE: "/",
  ERROR_AUTH: "/auth-error",

  // Developers
  //

  DEVELOPER_LIST: "/developer/list/:page",
  DEVELOPER_CREATE: "/developer/create",
  DEVELOPER_EDIT: "/developer/edit/:id",

  // Users
  //

  USERS_LIST: "/developer/:developer/users/list/:page",
  USER_CREATE: "/developer/:developer/user/create",
  USER_EDIT: "/developer/:developer/user/edit/:id",
};
