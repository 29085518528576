const TOKEN = "token";
const TOKEN_TIME_EXPIRED = "tokenTimeExpired";
const USER_INFO = "userInfo";
const SITE = "siteData";

export const cleanOutUser = () => {
  localStorage.removeItem(USER_INFO);
};

export const cleanSite = () => {
  localStorage.removeItem(SITE);
};

export const setSite = (data) => {
  localStorage.setItem(SITE, JSON.stringify(data));
};

export const getSite = () => {
  try {
    const siteDate = localStorage.getItem(SITE);
    if (!siteDate) {
      return null;
    }

    return JSON.parse(siteDate);
  } catch (e) {
    return null;
  }
};

export const getSiteKey = () => {
  try {
    const siteDate = localStorage.getItem(SITE);
    if (!siteDate) {
      return null;
    }

    return JSON.parse(siteDate).key;
  } catch (e) {
    return null;
  }
};

export const cleanOutTokens = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(TOKEN_TIME_EXPIRED);
};

export const getAccessToken = () => localStorage.getItem(TOKEN);
export const isFullRegistration = () => {
  const userInfo = JSON.parse(localStorage.getItem(USER_INFO));
  if (!userInfo) return false;
  return userInfo.isFullRegistration;
};
export const isPaymentDate = () => {
  const userInfo = JSON.parse(localStorage.getItem(USER_INFO));
  if (userInfo.isPaymentDate === false) return true;
  return false;
};

export const getRefreshToken = () => localStorage.getItem(TOKEN_TIME_EXPIRED);

export const getStoredUserInfo = () => {
  try {
    const info = localStorage.getItem(USER_INFO);
    if (!info) {
      return null;
    }

    return JSON.parse(info);
  } catch (e) {
    return null;
  }
};

export const setTokenData = (token, tokenTimeExpired) => {
  localStorage.setItem(TOKEN, token);
  localStorage.setItem(TOKEN_TIME_EXPIRED, tokenTimeExpired);
};

export const setUserData = (userInfo) => {
  localStorage.setItem(USER_INFO, JSON.stringify(userInfo));
};

export const setAuthData = (token, tokenTimeExpired, userInfo) => {
  setUserData(userInfo);
  setTokenData(token, tokenTimeExpired);
};

export const isTokensInStore = () => !!getAccessToken() && !!getRefreshToken();

export const logOut = async () => {
  cleanOutTokens();
  cleanOutUser();
};
