import Vue from "vue";
import VueRouter from "vue-router";
import ROUTE from "~/constants/routes";
import { getStoredUserInfo } from "../helpers/userAuth";

const Login = () => import("~/pages/Login.vue");
const Home = () => import("~/pages/Home.vue");
const PageNotFound = () => import("~/pages/error/PageNotFound.vue");

const UsersList = () => import("~/pages/users/item/usersList.vue");
const UserCreate = () => import("~/pages/users/item/userCreate.vue");
const UserEdit = () => import("~/pages/users/item/userEdit.vue");

const DeveloperList = () => import("~/pages/developer/list.vue");
const DeveloperCreate = () => import("~/pages/developer/create.vue");
const DeveloperEdit = () => import("~/pages/developer/edit.vue");

Vue.use(VueRouter);

const checkPrivateRoute = (to, from, next) => {
  if (getStoredUserInfo() === null) return next(ROUTE.LOGIN_PAGE);
  return next();
};

const adminPrivateRoute = (to, from, next) => {
  // console.log(getStoredUserInfo());
  // if (getStoredUserInfo().group[0].id !== 2) return next(ROUTE.LOGIN_PAGE);
  return next();
};

const routes = [
  {
    path: ROUTE.LOGIN_PAGE,
    name: "Login",
    component: Login,
  },

  // Home page

  {
    path: ROUTE.HOME_PAGE,
    name: "HOME",
    component: Home,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
    },
  },

  // Users Pages

  {
    path: ROUTE.USERS_LIST,
    name: "UsersList",
    component: UsersList,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
      adminPrivateRoute(to, from, next);
    },
  },
  {
    path: ROUTE.USER_CREATE,
    name: "UserCreate",
    component: UserCreate,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
      adminPrivateRoute(to, from, next);
    },
  },
  {
    path: ROUTE.USER_EDIT,
    name: "UserEdit",
    component: UserEdit,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
      adminPrivateRoute(to, from, next);
    },
  },

  // Developers Pages

  {
    path: ROUTE.DEVELOPER_LIST,
    name: "DeveloperList",
    component: DeveloperList,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
      adminPrivateRoute(to, from, next);
    },
  },
  {
    path: ROUTE.DEVELOPER_CREATE,
    name: "DeveloperCreate",
    component: DeveloperCreate,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
      adminPrivateRoute(to, from, next);
    },
  },
  {
    path: ROUTE.DEVELOPER_EDIT,
    name: "DeveloperEdit",
    component: DeveloperEdit,
    beforeEnter: (to, from, next) => {
      checkPrivateRoute(to, from, next);
      adminPrivateRoute(to, from, next);
    },
  },

  // 404 routes

  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",

  base: process.env.BASE_URL,

  routes,
});

export default router;
