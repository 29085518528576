export default {
  //login
  //
  LOG_IN: "/v1/auth/login",
  LOG_OUT: "/v1/auth/logout",
  GET_PROFILE: "/v1/users/profile",

  REGISTRATION: "/v1/register",

  //user
  //
  USER: "/v1/users/admin",
  CONTACT: "/v1/developer-contact-persons/admin",

  //developer
  //
  DEVELOPER: "/v1/developers/admin",

  QUOTA: "/v1/heygen-videos/system/remaining-quota",

  CREDIT: "/v1/billing-developer-credits/admin",
};
